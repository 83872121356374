import '../static/css/App.css';
import { Row, Col, Modal, Button } from 'react-bootstrap'
import CreatePage from './CreatePage'
import ViewNotesPage from './ViewNotesPage'
import SummaryPage from './SummaryPage'
import LoggingIn from './LoggingIn';
import Note from './Note'
import { useCookies } from 'react-cookie';
import React, { useEffect, useState } from 'react';
import AppContext from '../context/AppContext'
import { get, _delete } from '../api/API'
import { oauth2GetTokens } from '../utils/PKCE'
import { Link, useNavigate } from "react-router-dom";

import {
    Routes,
    Route
} from "react-router-dom";

function App() {
    // Used in determining the callback URL when signing into account via AWS Cognito
    let redirectURI = (window.location.href.indexOf("localhost") > -1) ? "http://localhost:3000" : "https://www.algostasher.com";

    const [cookies, setCookie, removeCookie] = useCookies(['ID_TOKEN', 'REFRESH_TOKEN', 'CODE_VERIFIER']);
    const navigate = useNavigate();

    // Modal state & logic:
    const [show, setShow] = useState(false);
    const handleCloseError = () => setShow(false);
    const handleShowError = () => setShow(true);
    const [error, setError] = useState("");

    // Store user's data:
    const [notes, setNotes] = useState({});
    const [username, setUsername] = useState("");

    // Track if we are waiting for the login process to finish after being redirected from Cognito UI
    const [loggingIn, setLoggingIn] = useState(false);

    // Only run id_token logic when component is rendered
    useEffect(() => {
        if (window.location.href.indexOf("code") > -1) {
            // We must have been redirected after log in, grab authorization code and obtain id token & refresh token.
            setLoggingIn(true);
            const currentUrl = new URL(window.location.href);
            const searchParams = new URLSearchParams(currentUrl.search);
            const authorizationCode = searchParams.get('code');
            const state = searchParams.get('state');
            let idToken = "", refreshToken = "";

            // Ensure that state matches to avoid CSRF attacks:
            if (state != cookies["STATE"]) {
                setLoggingIn(false);
                setError("Invalid redirect.");
                handleShowError();
                return;
            }

            // Remove code param from url so we don't re-attempt this on page refresh:
            window.history.pushState({}, document.title, window.location.pathname);

            oauth2GetTokens(redirectURI, authorizationCode, cookies["CODE_VERIFIER"])
                .then((response) => {
                    idToken = response.id_token;
                    refreshToken = response.refresh_token;
                    setCookie("ID_TOKEN", idToken, { path: '/' })
                    setCookie("REFRESH_TOKEN", refreshToken, { path: '/' })
                    setLoggingIn(false);
                    retrieveNotes(idToken, refreshToken);
                })
                .catch((error) => {
                    setLoggingIn(false);
                    setError(error);
                    handleShowError();
                })
        } else if (cookies["ID_TOKEN"]) {
            retrieveNotes(cookies["ID_TOKEN"], cookies["REFRESH_TOKEN"]);
        }
    }, [])

    function signout(e) {
        e.preventDefault();
        removeCookie('ID_TOKEN', { path: '/' });
        removeCookie('REFRESH_TOKEN', { path: '/' });
        removeCookie('CODE_VERIFIER', { path: '/' });
        window.location.href = "https://algostasher.auth.us-east-1.amazoncognito.com/logout?client_id=3lkpscp5kqg8t3nnas06m46a1i&logout_uri=" + redirectURI;
    }

    function sortNotes(sort_in) {
        setNotes(notes.sort((a,b) => (a[sort_in] > b[sort_in]) ? 1 : ((b[sort_in] > a[sort_in]) ? -1 : 0)));
    }

    function deleteNote(name) {
        // Remove note from database
        _delete('https://tkx9tjkm3d.execute-api.us-east-1.amazonaws.com/Prod/notes/delete_note/' + name)
            .then((response) => {
                // Remove note locally, then redirect
                for(let i = notes.length - 1; i >= 0; --i) {
                    if (notes[i].title === name) {
                        notes.splice(i, 1);
                    }
                }
                navigate("/");
            })
            .catch((error) => {
                setError(error);
                handleShowError();
            })


    }

    // Retrieve all of the user's notes:
    function retrieveNotes(idToken, refreshToken) {
        get('https://tkx9tjkm3d.execute-api.us-east-1.amazonaws.com/Prod/notes/get_all_notes')
            .then((response) => {
                setNotes(response.data);
                setUsername(response.username);
            })
            .catch((error) => {
                setError(error);
                handleShowError();
            })
    }

    return (
        <div className="main-div">
            {loggingIn ? (
                <LoggingIn>
                </LoggingIn>
            ) : cookies["ID_TOKEN"] ? (
                <div className='app container-fluid' >
                    <Row>
                        <Col className='app-header gx-0 d-flex align-items-center'>
                            <Link to="/" className="navbar-brand theme-color menu-margin logo-text"> AlgoStasher </Link>
                            <Link to="/create" className="navbar-brand theme-color header-text">Create Note</Link>
                            <Link to="/" className="navbar-brand theme-color header-text">View Notes</Link>
                        </Col>
                        <Col className="app-header gx-0 d-flex align-items-center justify-content-end">
                            <a className="navbar-brand theme-color header-text" href="/" onClick={(e) => signout(e)}>Log Out</a>
                        </Col>
                    </Row>
                    <AppContext.Provider value={{
                        sortNotes: sortNotes,
                        deleteNote: deleteNote,
                        username: username,
                        setError: setError,
                        handleShowError: handleShowError
                        }}>
                        <Routes>
                            <Route exact path="/" element={<ViewNotesPage notes={notes} />}>
                            </Route>
                            <Route exact path="/create" element={<CreatePage />}>
                            </Route>
                            <Route exact path="/edit" element={<CreatePage />}>
                            </Route>
                            <Route exact path="/view" element={<ViewNotesPage notes={notes} />}>
                            </Route>
                            <Route exact path="/notes/:username/:title" element={<Note notes={notes} />}>
                            </Route>
                        </Routes>
                    </AppContext.Provider>
                    <Row className='d-flex justify-content-center credit-row'>
                        <p className='credits'>
                            AlgoStasher created by <a href="https://www.linkedin.com/in/nathan-rossol-131092143/"> Nathan Rossol </a>
                        </p>
                    </Row>
                </div>
            ) : (
                <SummaryPage>
                </SummaryPage>
            )}
            <Modal show={show} onHide={handleCloseError}>
                <Modal.Header closeButton>
                    <Modal.Title>Uh Oh!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="button-color" onClick={handleCloseError}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default App;
