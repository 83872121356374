import '../static/css/ViewNotesPage.css';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import NoteBox from './NoteBox'
import React, { useContext, useState } from 'react';
import AppContext from '../context/AppContext'
import { Link } from "react-router-dom";

function ViewNotesPage(props) {
    const { sortNotes } = useContext(AppContext);
    const [sort, setSort] = useState("title");

    function handleSort(e) {
        if (props.notes.length > 0) {
            sortNotes(e.target.value);
            setSort(e.target.value);
        }
    }

    return (
        <Row className='d-flex justify-content-center'>
            <Card className="main-card">
                <Card.Body>
                    <Row>
                        <h4>
                            Your Notes:
                        </h4>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Sort By:</Form.Label>
                            <Form.Select className='sort-select' onChange={handleSort}>
                                <option value="title">Title</option>
                                <option value="topic">Topic</option>
                                <option value="difficulty">Difficulty</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    {props.notes === undefined || props.notes.length === 0 ? (
                        <Col className='d-flex justify-content-center'>
                            <Link to="/create">
                                <Button variant="primary" type="submit" className="button-color">
                                    Create Your First Note
                                </Button>
                            </Link>

                        </Col>
                    ) : (
                        <Row>
                            {Object.entries(props.notes).map((note, index) => {
                                return <NoteBox note={note} key={index} />
                            })}
                        </Row>
                    )}

                </Card.Body>
            </Card>
        </Row>
    )
}

export default ViewNotesPage;
