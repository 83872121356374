//////////////////////////////////////////////////////////////
// This file contains fetch wrappers for simpler API calls. //
//////////////////////////////////////////////////////////////


import { oauth2RefreshTokens } from '../utils/PKCE'
import { getCookie, setCookie } from '../utils/cookies'


async function post(url, body) {
    const idToken = await checkTokenExpiration();
    if (idToken === null) {
        return handleInvalidTokens();
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': idToken
        },
        body: JSON.stringify(body),
        crossDomain: true
    }
    return handleFetch(url, requestOptions);
}

async function get(url) {
    const idToken = await checkTokenExpiration();
    if (idToken === null) {
        return handleInvalidTokens();
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': idToken
        },
        crossDomain: true
    }
    return handleFetch(url, requestOptions);
}

async function _delete(url) {
    const idToken = await checkTokenExpiration();
    if (idToken === null) {
        return handleInvalidTokens();
    }

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': idToken
        },
        crossDomain: true
    }
    return handleFetch(url, requestOptions);
}

async function handleFetch(url, requestOptions) {
    return new Promise(function(resolve, reject) {
        fetch(url, requestOptions)
            .then((response) => {
                if (response.status !== 200) {
                    // It is likely that the user's id_token is expired, use the
                    // refresh token to acquire a new one:
                    reject("Got a bad response from the API. Please log out and then log back in. This will be fixed in the future.");
                } else {
                    response.json().then((response) => {
                        if (response.success === false) {
                            reject(response.message);
                        } else {
                            resolve(response);
                        }
                    })
                }
            })
            .catch((error) => {
                reject("Got a bad response from the API. Please log out and then log back in. This will be fixed in the future.")
            })
    })
}

/*
 / Checks the user's id token to see if it is expired (or very close to expiration).
 / If so, uses the user's refresh token to retrieve a new set of tokens. Returns valid id token if we are good to make API call.
*/
async function checkTokenExpiration() {
    const idToken = getCookie("ID_TOKEN");
    const refreshToken = getCookie("REFRESH_TOKEN");

    if (isTokenExpired(idToken)) {
        try {
            const response = await oauth2RefreshTokens(refreshToken);
            // Update ID_TOKEN cookie and also return the new id token to caller.
            setCookie("ID_TOKEN", response.id_token);
            return response.id_token;
        } catch (error) {
            return null;
        }
    }


    return idToken;
}
        
/*
 / Token is considered expired if it is actually expired or is <= 60 seconds away from expiration.
*/
function isTokenExpired(idToken) {
    const tokenPayload = JSON.parse(atob(idToken.split('.')[1]));
    const expirationTime = tokenPayload.exp;
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

    return currentTime + 60 >= expirationTime;
}

/*
 / Just return a auto-reject promise so that the caller of these methods will detect an error in its .then() call.
*/
function handleInvalidTokens() {
    return new Promise(function(resolve, reject) {
        reject("There was an issue authenticating the user, please log out and log in again.")
    })
}

export { get, post, _delete}