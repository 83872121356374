import '../static/css/CreatePage.css';
import { Row, Col, Card, Button, Form, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import React, { useState, useContext, useEffect } from 'react';
import AppContext from '../context/AppContext';
import { get, post } from '../api/API'
import CodeMirror from '@uiw/react-codemirror';
import { useLocation } from 'react-router-dom';
import { basicSetup} from "codemirror";
import { languages } from "@codemirror/language-data"
import { LanguageDescription, LanguageSupport } from "@codemirror/language"

function CreatePage(props) {
    // API call logic
    const [cookies] = useCookies(['ID_TOKEN', 'REFRESH_TOKEN', 'CODE_VERIFIER']);

    // Form logic
    const [validated, setValidated] = useState(false);
    const { username, setError, handleShowError } = useContext(AppContext);

    // Used for pre-populating form with note state if we are editing a note.
    const location = useLocation();
    const state = location.state === null ? {"note": {}} : location.state;

    // State for code editor
    const languageList = ["C++", "JavaScript", "Java", "Python", "HTML", "CSS"];
    const [code, setCode] = useState(state.note.code);
    const [language, setLanguage] = useState(state.note.language);
    const [codeMirrorLang, setCodeMirrorLang] = useState({});

    // State for Code Insights
    const [isAnalyzeVisible, setAnalyzeVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [insights, setInsights] = useState("");

    // Set language extension of codeMirror box right away.
    useEffect(() => {
        if (language !== undefined) {
            updateCodeMirrorExtension(language);
        } else {
            updateCodeMirrorExtension("C++");
        }
    }, [])

    // Handler for creating a new note.
    function handleCreate(e) {
        e.preventDefault();

        // Check form validation:
        let form = e.target;
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }

        // Obtain all user input
        let formData = new FormData(form);
        formData = Object.fromEntries(formData.entries());
        formData['code'] = code;
        formData['language'] = (language === undefined || language === null || language === "") ? "C++" : language;

        // Determine if we are creating or editing
        let url = "";
        if (location.pathname === "/create") {
            url = 'https://tkx9tjkm3d.execute-api.us-east-1.amazonaws.com/Prod/notes/create_note'
        } else {
            url = 'https://tkx9tjkm3d.execute-api.us-east-1.amazonaws.com/Prod/notes/edit_note'
            formData['old_title'] = state.note.title; // Used in back-end if editing.
        }

        // Call API
        post(url, formData)
            .then((response) => {
                window.location.href = "/notes/" + username + "/" + formData["title"];
            })
            .catch((error) => {
                setError(error);
                handleShowError();
            })
    }

    function handleLanguage(e) {
        setLanguage(e.target.value);
        updateCodeMirrorExtension(e.target.value);
    }

    function onCodeChange(value) {
        setCode(value);
    }

    function handleAnalyze(e) {
        e.preventDefault();
        setAnalyzeVisible(false);
        setInsights(null);
        setLoading(true);

        let data = {
            "code": code
        }

        post('https://tkx9tjkm3d.execute-api.us-east-1.amazonaws.com/Prod/notes/analyze_note', data)
            .then((response) => {
                // Trim empty lines from insights generated
                let ai_response = response.message.choices[0].message.content;
                ai_response = ai_response.split("\n");
                ai_response = ai_response.filter((insight) => insight.trim() !== "");
                setLoading(false);
                setAnalyzeVisible(true);
                setInsights(ai_response);
            })
            .catch((error) => {
                setLoading(false);
                setError(error);
                handleShowError();
            })
    }

    // Updates the extension used by CodeMirror to match the selected language.
    function updateCodeMirrorExtension(newLanguage) {
        let langDescription = LanguageDescription.matchLanguageName(languages, newLanguage, true);
        if (langDescription === null) {
            langDescription = LanguageDescription.matchLanguageName(languages, "C++", true);
        }
        langDescription.load().then((supportClass) => {
            setCodeMirrorLang(supportClass); 
        });
    }

    return (
        <Row className='d-flex justify-content-center'>
            <Card className="main-card">
                <Card.Body>
                    <Row>
                        <Form onSubmit={handleCreate} noValidate validated={validated}>
                            <Row>
                                <h4>
                                    Problem Background
                                </h4>
                            </Row>
                            <Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="title">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control required type="text" defaultValue={state.note.title} placeholder="Enter problem name." className="create-field" name="title" pattern="^[ a-zA-Z0-9_]+$" />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a a valid title for this note. Cannot include special characters.
                                            </Form.Control.Feedback>
                                            <Form.Text className="text-muted">
                                                Example: Minimum Window Substring
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="topic">
                                            <Form.Label>Topic</Form.Label>
                                            <Form.Control required type="text" defaultValue={state.note.topic} placeholder="Enter problem topic." className="create-field" name="topic" />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a topic for this note.
                                            </Form.Control.Feedback>
                                            <Form.Text className="text-muted">
                                                Example: Depth First Search
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="difficulty">
                                            <Form.Label>Difficulty</Form.Label>
                                            <Form.Control required type="text" defaultValue={state.note.difficulty} placeholder="Enter difficulty." className="create-field" name="difficulty" />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a difficulty for this note.
                                            </Form.Control.Feedback>
                                            <Form.Text className="text-muted">
                                                Example: Easy, Medium, Hard.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="link">
                                            <Form.Label>Link</Form.Label>
                                            <Form.Control type="text" defaultValue={state.note.link} placeholder="Enter link." className="create-field" name="link" />
                                            <Form.Text className="text-muted">
                                                Link to the problem.
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId="description">
                                        <Form.Label>Problem Description</Form.Label>
                                        <Form.Control required as="textarea" defaultValue={state.note.description} placeholder="Enter the problem's description." className="create-field" name="description" />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter this problem's description.
                                        </Form.Control.Feedback>
                                        <Form.Text className="text-muted">
                                            Describe what you are trying to solve.
                                        </Form.Text>
                                    </Form.Group>
                                </Row>
                            </Row>
                            <hr className="my-4"/>
                            <Row>
                                <h4>
                                    Solution
                                </h4>
                            </Row>
                            <Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId="explanation">
                                        <Form.Label>Thought Process</Form.Label>
                                        <Form.Control required as="textarea" defaultValue={state.note.explanation} placeholder="Enter an explanation." className="create-field" name="explanation" />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your thought process.
                                        </Form.Control.Feedback>
                                        <Form.Text className="text-muted">
                                            A written explanation of how to solve this problem.
                                        </Form.Text>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId="language">
                                        <Form.Label>Language</Form.Label>
                                        <Form.Select aria-label="Select Language" value={language} onChange={handleLanguage} className="create-field">
                                            {languageList.map((lang, index) => {
                                                return <option key={index}> {lang} </option>
                                            })}
                                        </Form.Select>
                                        <Form.Text className="text-muted">
                                            Choose a language to write your code in.
                                        </Form.Text>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    {codeMirrorLang instanceof LanguageSupport ? (
                                        <CodeMirror className="mb-3"
                                            value={state.note.code}
                                            extensions={[basicSetup, codeMirrorLang]}
                                            onChange={onCodeChange}
                                        />
                                    ) : ( 
                                        <CodeMirror className="mb-3"
                                            value={state.note.code}
                                            extensions={[basicSetup]}
                                            onChange={onCodeChange}
                                        />                                       
                                    )}
                                </Row>

                                {isAnalyzeVisible ? (
                                    <div>
                                        <hr className="my-4"/>
                                        <Row className='d-flex justify-content-center analyze-section'>
                                            <h4>Code Insights</h4>
                                            <Col className='insight-padding'>
                                                {insights.map((insight, index) => (
                                                    <React.Fragment key={index}>
                                                        {insight}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </Col>
                                        </Row> 
                                    </div>
                                ) : isLoading ? (
                                    <Row className='d-flex justify-content-center analyze-section'>
                                        <Spinner animation="border" role="status"></Spinner>
                                    </Row>
                                ) : null}

                                <Row className='d-flex justify-content-center'>
                                    <Col className="col-md-4 col-centered">
                                        {location.pathname === "/create" ? (
                                            <Button variant="primary" type="submit" className="button-color create-button">
                                                Create Note
                                            </Button>
                                        ) : (
                                            <Button variant="primary" type="submit" className="button-color create-button">
                                                Save Edit
                                            </Button>
                                        )}
                                    </Col>
                                    <Col className="col-md-4 col-centered">
                                        <OverlayTrigger key='analyze-trigger' placement='right' overlay={
                                            <Tooltip id='analyze-tooltip'>
                                                Gain instant AI insights into how you can improve your code! (Experimental).
                                            </Tooltip>
                                        }>
                                            <Button variant="primary" className="button-color create-button" onClick={handleAnalyze}>
                                                Analyze Code
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>

                            </Row>
                        </Form>
                    </Row>
                </Card.Body>
            </Card>
        </Row>
    )
}

export default CreatePage;
