import '../static/css/SummaryPage.css';
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import createImage from '../static/images/create.png';
import viewImage from '../static/images/view.png';
import aiImage from '../static/images/insights.JPG'
import { generatePKCEPair, generateState } from '../utils/PKCE'
import { useCookies } from 'react-cookie';

function SummaryPage() {
    let redirectURI = (window.location.href.indexOf("localhost") > -1) ? "http://localhost:3000" : "https://www.algostasher.com";

    const [cookies, setCookie, removeCookie] = useCookies(['ID_TOKEN', 'REFRESH_TOKEN', 'CODE_VERIFIER']);

    function signIn(e) {
        e.preventDefault();
        // Make call to Cognito hosted UI using the Authorization Code OAuth grant with PKCE. The redirect from this hosted UI will give us
        // the authorization code. We generate a code verifier (random string) and hash it using SHA256 to derive a code challenge.
        // The code verifier is known only by the browser app, whereas the code challenge is known by Cognito and is used to verify
        // our identity.

        const pkcePair = generatePKCEPair();
        const state = generateState();
        window.location.href = "https://algostasher.auth.us-east-1.amazoncognito.com/login?client_id=3lkpscp5kqg8t3nnas06m46a1i&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&code_challenge=" + pkcePair.codeChallenge + "&state=" + state + "&code_challenge_method=S256&redirect_uri=" + redirectURI;
        setCookie("CODE_VERIFIER", pkcePair.codeVerifier, { path: '/' })
        setCookie("STATE", state, { path: '/' })
    }

    return (
        <div className="background">
            <div className='jumbotron'>
                <Container className='text-center'>
                    <h1 className="display-4">Welcome to AlgoStasher!</h1>
                    <p className="lead">The best place to store and study all of your coding notes.</p>
                    <hr className="my-4" />
                    <p>Sign up or log in to get started.</p>
                    <Button variant="primary" type="submit" className="button-color summary-button" onClick={(e) => {signIn(e)}}>
                        Log In
                    </Button>
                </Container>
            </div>
            <Container className="mt-5 d-flex justify-content-center">
                <Row>
                    <Col lg={12} md={12} sm={12} className="mb-4">
                        <Card h={100} className='feature-card'>
                            <Card.Body>
                                <Card.Title className="card-padding"><h4>Easily store your coding problem solutions from websites such as LeetCode, HackerRank, and CodeSignal.</h4></Card.Title>
                                <Card.Img src={createImage}></Card.Img>
                            </Card.Body>
                        </Card>
                    </Col>
                    <hr className="my-4" />
                    <Col lg={12} md={12} sm={12} className="mb-4">
                        <Card h={100} className='feature-card'>
                            <Card.Body>
                                <Card.Title className="card-padding"><h4>Get valuable insights using artificial intelligence on how to improve your code.</h4></Card.Title>
                                <Card.Img src={aiImage}></Card.Img>
                            </Card.Body>
                        </Card>
                    </Col>
                    <hr className="my-4" />
                    <Col lg={12} md={12} sm={12} className="mb-4">
                        <Card h={100} className='feature-card'>
                            <Card.Body>
                                <Card.Title className="card-padding"><h4>Study your notes at any time and on any device.</h4></Card.Title>
                                <Card.Img src={viewImage}></Card.Img>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SummaryPage;
