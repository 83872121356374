import '../static/css/SummaryPage.css';
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import createImage from '../static/images/create.png';
import viewImage from '../static/images/view.png';
import aiImage from '../static/images/insights.JPG'
import { generatePKCEPair } from '../utils/PKCE'
import { useCookies } from 'react-cookie';

function LoggingIn() {
    return (
        <div className="background">
            <div className='jumbotron'>
                <Container className='text-center'>
                    <h1 className="display-4">Logging in...</h1>
                </Container>
            </div>
        </div>
    )
}

export default LoggingIn;
