/////////////////////////////////////////////////////////////////////////////////////
// This file contains methods to read/update browser cookies without react-cookie. //
/////////////////////////////////////////////////////////////////////////////////////

function getCookie(name) {
    // Get string that represents all cookies stored.
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
}

function setCookie(name, newValue) {
    document.cookie = name + "=" + newValue + "; path=/";
}

export { getCookie, setCookie }