import '../static/css/NoteBox.css';
import { Card, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import React, { useContext } from 'react';
import AppContext from '../context/AppContext'

function NoteBox(props) {
    const {username} = useContext(AppContext);

    return (
        <Card className="note-box-card overflow-auto">
            <Card.Body>
                <Row className="title-row">
                    <Card.Title>
                        <Link to={"/notes/" + username + "/" + props.note[1].title} className='no-underline-link theme-color stretched-link'>
                            {props.note[1].title}
                        </Link>
                    </Card.Title>
                </Row>
                <Row>
                    <Card.Text className="ellipsis">
                        {props.note[1].topic}
                    </Card.Text>
                </Row>
                <Row>
                    <Card.Text className="ellipsis">
                        {props.note[1].difficulty}
                    </Card.Text>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default NoteBox;