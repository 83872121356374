///////////////////////////////////////////////////////////////////////////
// This file contains methods to interact with the OAuth2API lambda API. //
///////////////////////////////////////////////////////////////////////////


import {randomBytes, createHash} from "crypto-browserify";


/*
 / Generates PKCE code verifier and code challenge.
*/
function generatePKCEPair() {
    const NUM_OF_BYTES = 22; // Total of 44 characters (1 Bytes = 2 char) (standard states that: 43 chars <= verifier <= 128 chars)
    const HASH_ALG = "sha256";
    const randomVerifier = randomBytes(NUM_OF_BYTES).toString('hex')
    const hash = createHash(HASH_ALG).update(randomVerifier).digest('base64');
    const challenge = hash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Clean base64 to make it URL safe
    return {
        'codeVerifier': randomVerifier, 
        'codeChallenge': challenge
    }
}

/*
 / Calls the OAuth2 Lambda that in turn performs the token exchange with Cognito using authorization code with PKCE.
*/
function oauth2GetTokens(redirectUri, authorizationCode, codeVerifier) {
    const requestBody = {
        'code': authorizationCode,
        'codeVerifier': codeVerifier,
        'redirectUri': redirectUri,
        'grantType': "authorization_code"
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        crossDomain: true
    }

    return handleOAuth2Fetch("https://6fhveya34a.execute-api.us-east-1.amazonaws.com/Prod", requestOptions);
}

function oauth2RefreshTokens(refreshToken) {
    const requestBody = {
        'refreshToken': refreshToken,
        'grantType': "refresh_token"
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        crossDomain: true
    }

    return handleOAuth2Fetch("https://6fhveya34a.execute-api.us-east-1.amazonaws.com/Prod", requestOptions)
}

function handleOAuth2Fetch(url, requestOptions) {
    return new Promise(function(resolve, reject) {
        fetch(url, requestOptions)
            .then((response) => {
                if (response.status !== 200) {
                    reject("Unable to authenticate user.");
                } else {
                    response.json().then((response) => {
                        resolve(response);
                    })
                }
            })
            .catch((error) => {
                reject("Unable to authenticate user.")
            })
    })
}

function generateState() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const stateLength = 16;

    let state = '';
    for (let i = 0; i < stateLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        state += characters.charAt(randomIndex);
    }

    return state;
}

export { generatePKCEPair, oauth2GetTokens, oauth2RefreshTokens, generateState }