import '../static/css/Note.css';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../context/AppContext'
import _ from 'lodash';
import CodeMirror from '@uiw/react-codemirror';
import { get } from '../api/API'
import { basicSetup} from "codemirror";
import { languages } from "@codemirror/language-data"
import { LanguageDescription, LanguageSupport } from "@codemirror/language"

 
function Note(props) {
    const [note, setNote] = useState({});
    const [cookies] = useCookies(['ID_TOKEN', 'REFRESH_TOKEN', 'CODE_VERIFIER']);
    const [codeMirrorLang, setCodeMirrorLang] = useState({});

    const {setError, handleShowError, deleteNote} = useContext(AppContext);
    const navigate = useNavigate();

    // Delete modal state:
    const [show, setShow] = useState(false);
    const handleCloseWarning = () => setShow(false);
    const handleShowWarning = () => setShow(true);


    // Find the note we want to look at.
    function getNoteData() {
        const title = window.location.pathname.split("/").at(-1);

        get('https://tkx9tjkm3d.execute-api.us-east-1.amazonaws.com/Prod/notes/get_note/' + title)
            .then((response) => {
                setNote(response.data);

                // Get LanguageDescription object for language to be used by CodeMirror
                // Then, load the language's extension to be used in the extensions array of CodeMirror
                if (response.data.language !== undefined && response.data.language !== "") {
                    let langDescription = LanguageDescription.matchLanguageName(languages, response.data.language, true);
                    langDescription.load().then((supportClass) => {
                        setCodeMirrorLang(supportClass); 
                    });
                }
            })
            .catch((error) => {
                setError(error);
                handleShowError();
            })
    }

    useEffect(() => {
        getNoteData();
    }, [])

    function handleDeleteWarning(e) {
        e.preventDefault();
        handleShowWarning();
    }

    function handleEdit() {
        navigate("/edit", {state:{note: note, edit: true}});
    }

    return (
        <div>
            {!_.isEmpty(note) &&
                <Row className='d-flex justify-content-center'>
                    <Card className='main-card'>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h4>
                                        {note.title}
                                    </h4>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <a className='description no-underline-link theme-color' href="" onClick={handleEdit}>
                                        Edit
                                    </a>
                                    <a className='description no-underline-link theme-color' href="" onClick={handleDeleteWarning}>
                                        Delete
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Row>
                                    <p className='description'>
                                        Difficulty - {note.difficulty}
                                    </p>
                                </Row>
                                <Row>
                                    <p className='description'>
                                        Topic - {note.topic}
                                    </p>
                                </Row>
                                <Row>
                                    <a className='description no-underline-link theme-color' href={note.link}>
                                        View Problem
                                    </a>
                                </Row>
                            </Row>
                            <Row>
                                <h4 className='title-margin'>
                                    Problem Description
                                </h4>
                                <Row>
                                    <p className='description'>
                                        {note.description}
                                    </p>
                                </Row>
                            </Row>
                            <Row>
                                <h4 className='title-margin'>
                                    Thought Process
                                </h4>
                                <Row>
                                    <p className='description'>
                                        {note.explanation}
                                    </p>
                                </Row>
                            </Row>
                            <Row>
                                <h4 className='title-margin'>
                                    Code ({note.language})
                                </h4>
                                <Row className='code-margin'>
                                    {codeMirrorLang instanceof LanguageSupport ? (
                                        <CodeMirror className="mb-3 code-mirror"
                                            value={note.code}
                                            extensions={[basicSetup, codeMirrorLang]}
                                            readOnly="true" 
                                        /> 
                                    ) : ( 
                                        <CodeMirror className="mb-3 code-mirror"
                                            value={note.code}
                                            extensions={[basicSetup]}
                                            readOnly="true"
                                        />                                         
                                    )}

                                </Row>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Modal show={show} onHide={handleCloseWarning}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Your Note</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete this note?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="button-color" onClick={handleCloseWarning}>
                                Cancel
                            </Button>
                            <Button variant="primary" className="button-color" onClick={() => deleteNote(note.title)}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
            }
        </div>
    );
}

export default Note;